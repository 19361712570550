import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/user/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocations() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/role/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
